/**
 * This file was @generated using pocketbase-typegen
 */

export enum Collections {
  Pets = "pets",
  Roles = "roles",
  Users = "users",
  WhichGeneratorPartTypes = "which_generator_part_types",
  WitchApplication = "witch_application",
  WitchGeneratorParts = "witch_generator_parts",
  WitchOutfitTypes = "witch_outfit_types",
  WitchOutfits = "witch_outfits",
  Witches = "witches",
  WitchItems = "witch_items",
  Fairies = "fairies",
  FairyFriends = "fairy_friends",
  Items = "items",
  Colors = "colors",
  FoodItems = "food_items",
  Shops = "shops",
  ShopItems = "shop_items",
  EntertainmentItems = "entertainment_items",
  ItemCategories = "item_categories",
  Flavors = "flavors",
  Genres = "genres",
}

export enum InventoryItemCollection {
  FOOD = "FOOD",
  ENTERTAINMENT = "ENTERTAINMENT",
}

// Alias types for improved usability
export type IsoDateString = string;
export type RecordIdString = string;
export type HTMLString = string;

// System fields
export type BaseSystemFields<T = never> = {
  id: RecordIdString;
  created: IsoDateString;
  updated: IsoDateString;
  collectionId: string;
  collectionName: Collections;
  expand?: T;
};

export type AuthSystemFields<T = never> = {
  email: string;
  emailVisibility: boolean;
  username: string;
  verified: boolean;
} & BaseSystemFields<T>;

// Record types for each collection

export type PetsRecord = {
  id: RecordIdString;
  image: string;
  name: string;
  witch: RecordIdString;
  affection: number;
  personality: string;
};

export type RolesRecord = {
  name?: string;
};

export type UsersRecord = {
  avatar: string;
  username: string;
  name?: string;
  id: string;
  roles: RecordIdString;
};

export type WhichGeneratorPartTypesRecord = {
  name: string;
};

export type WitchGeneratorData = {
  name: string;
  birth_day: number;
  birth_month: number;
  brow: RecordIdString;
  eye: RecordIdString;
  mouth: RecordIdString;
  body: RecordIdString;
  sock: RecordIdString;
  shoe: RecordIdString;
  hair_color_dark: string;
  hair_color_main: string;
  hair_color_light: string;
  hair_description: string;
  dress_color_main: string;
  dress_color_sub: string;
  accessories_description: string;
  created_at: string;
};

export type WitchApplicationRecord = {
  id: RecordIdString;
  user: RecordIdString;
  name: string;
  birth_day: number;
  birth_month: number;
  brow: RecordIdString;
  eye: RecordIdString;
  mouth: RecordIdString;
  body: RecordIdString;
  sock: RecordIdString;
  shoe: RecordIdString;
  hair_color_dark: string;
  hair_color_main: string;
  hair_color_light: string;
  hair_description: string;
  dress_color_main: string;
  dress_color_sub: string;
  accessories_description: string;
  hair_sketch: File | null;
  accessories_sketch: File | null;
  symbol: RecordIdString;
  created_at: string;
  expand: {
    symbol: SymbolRecord;
  };
};

export type WitchGeneratorPartsRecord = {
  id: string;
  image: string;
  name: string;
  type: RecordIdString;
  expand: {
    type: WhichGeneratorPartTypesRecord;
  };
};

export type WitchOutfitTypesRecord = {
  name?: string;
};

export type WitchOutfitsRecord = {
  id: string;
  image: string;
  name: string;
  witch: RecordIdString;
  is_current: boolean;
};

export type WitchDressesRecord = {
  id: RecordIdString;
  exam_type: RecordIdString;
  witch: RecordIdString;
  image: string;
  expand: {
    exam_type: ExamTypesRecord;
    witch: WitchesRecord;
  };
};

export type FairyLevel = "BABY" | "KID" | "GROWN";

export type FairyOutfitsRecord = {
  id: string;
  image: string;
  name: string;
  fairy: RecordIdString;
  is_current: boolean;
  fairy_level: FairyLevel;
};

export type WitchesRecord = {
  id: string;
  name: string;
  user: RecordIdString;
  birthday_month: number;
  birthday_day: number;
  money_crystal: number;
  symbol: RecordIdString;
  expand: {
    symbol: SymbolRecord;
  };
  created: string;
};

export type WitchesExamRecord = {
  id: string;
  witch: RecordIdString;
  exam: RecordIdString;
  level: number;
  expand: {
    exam: ExamRecord;
  };
};

export type RewardType = "money" | "description";

export type RewardDataMoney = {
  type: "money";
  money: {
    moneyCrystal: number;
  };
};

export type RewardDataDescription = {
  type: "description";
  description: string;
};

export type RewardData = RewardDataMoney | RewardDataDescription;

export type ExamData = {
  task_description: string;
  rewards: RewardData[];
};

export type ExamRecord = {
  id: string;
  name: string;
  levels: number;
  order: number;
  exam_descriptions: ExamData[];
  symbol: string[];
};

export type SymbolRecord = {
  id: string;
  name: string;
  image: string;
};

// Response types include system fields and match responses from the PocketBase API
export type PetsResponse<Texpand = unknown> = Required<PetsRecord> &
  BaseSystemFields<Texpand>;
export type RolesResponse<Texpand = unknown> = Required<RolesRecord> &
  BaseSystemFields<Texpand>;
export type UsersResponse<Texpand = unknown> = Required<UsersRecord> &
  AuthSystemFields<Texpand>;
export type WhichGeneratorPartTypesResponse<Texpand = unknown> =
  Required<WhichGeneratorPartTypesRecord> & BaseSystemFields<Texpand>;
// export type WitchApplicationResponse<Twhich_generator_data = unknown, Texpand = unknown> = Required<WitchApplicationRecord<Twhich_generator_data>> & BaseSystemFields<Texpand>
export type WitchGeneratorPartsResponse<Texpand = unknown> =
  Required<WitchGeneratorPartsRecord> & BaseSystemFields<Texpand>;
export type WitchOutfitTypesResponse<Texpand = unknown> =
  Required<WitchOutfitTypesRecord> & BaseSystemFields<Texpand>;
export type WitchOutfitsResponse<Texpand = unknown> =
  Required<WitchOutfitsRecord> & BaseSystemFields<Texpand>;
export type WitchesResponse<Texpand = unknown> = Required<WitchesRecord> &
  BaseSystemFields<Texpand>;

// Types containing all Records and Responses, useful for creating typing helper functions

export type CollectionRecords = {
  pets: PetsRecord;
  roles: RolesRecord;
  users: UsersRecord;
  which_generator_part_types: WhichGeneratorPartTypesRecord;
  witch_application: WitchApplicationRecord;
  witch_generator_parts: WitchGeneratorPartsRecord;
  witch_outfit_types: WitchOutfitTypesRecord;
  witch_outfits: WitchOutfitsRecord;
  witches: WitchesRecord;
};

export type CollectionResponses = {
  pets: PetsResponse;
  roles: RolesResponse;
  users: UsersResponse;
  which_generator_part_types: WhichGeneratorPartTypesResponse;
  // witch_application: WitchApplicationResponse
  witch_generator_parts: WitchGeneratorPartsResponse;
  witch_outfit_types: WitchOutfitTypesResponse;
  witch_outfits: WitchOutfitsResponse;
  witches: WitchesResponse;
};

export type ShopsRecord = {
  id: string;
  name: string;
  description: string;
  image: string;
};

export type ShopsItemsRecord = {
  id: RecordIdString;
  shop: RecordIdString;
  item: RecordIdString;
  money_crystal: number;
  expand: {
    item: ItemsRecord;
  };
};

export type ItemsRecord = {
  id: RecordIdString;
  name: string;
  description: string;
  image: string;
  attributes: ItemAttributes;
  category: RecordIdString;
};

export type FoodItemRecord = {
  id: RecordIdString;
  saturation: number;
  item: RecordIdString;
  flavor: RecordIdString;
  expand: {
    item: ItemsRecord;
    flavor: FlavorRecord;
  };
};

export type GenreRecord = {
  id: RecordIdString;
  name: string;
};

export type EntertainmentItemRecord = {
  id: RecordIdString;
  happiness: number;
  color: RecordIdString;
  item: RecordIdString;
  genre: RecordIdString;
  expand: {
    item: ItemsRecord;
    color: ColorRecord;
    genre: GenreRecord;
  };
};

export type ItemAttributes =
  | undefined
  | ItemAttributesFood
  | ItemAttributesEntertainment;

export type FoodTaste = "sweet" | "sour";

export type ItemAttributesFood = {
  type: "food";
  saturation: number;
};

export type ItemAttributesEntertainment = {
  type: "entertainment";
  happiness: number;
};

export type ItemCategoriesRecord = {
  id: string;
  name: string;
};

export type ExamSubmissionRecord = {
  id: string;
  witch: RecordIdString;
  exam_level: RecordIdString;
  image: string;
  text?: string;
  status: "open" | "success" | "fail";
  created: string;
  expand: {
    witch: WitchesRecord;
    exam_level: ExamLevelsRecord;
  };
};

export type WitchItemsRecord = {
  id: string;
  witch: RecordIdString;
  item: RecordIdString;
  quantity: number;
  expand: {
    item: ItemsRecord;
  };
};

type HtmlString = string;

export type ExamTypesRecord = {
  id: string;
  name: string;
  grade: number;
  levels: number;
  base_archievment_image: string;
};

export type ExamLevelsRecord = {
  id: string;
  exam_type: RecordIdString;
  level: number;
  task_description: HtmlString;
  reward_description: HtmlString;
  reward_money_crystal: number;
  reward_money_crystal_large: number;
  reward_money_magic_heart: number;
  archievment_image: string;
  task_file: string;
  witches_passed: RecordIdString[];
  expand: {
    exam_type: ExamTypesRecord;
  };
};

export type ShopCouponRecord = {
  id: RecordIdString;
  coupon: RecordIdString;
  shop: RecordIdString;
  expand: {
    coupon: ItemsRecord;
    shop: ShopsRecord;
  };
};

export type NewsRecord = {
  id: RecordIdString;
  title: string;
  content: string;
  image_url: string;
  witch: RecordIdString;
  pet?: RecordIdString;
  fairy?: RecordIdString;
  is_read: boolean;
  created: string;
  expand: {
    witch: WitchesRecord;
    pet: PetsRecord;
    fairy: FairyRecord;
  };
};

export type NatureRecord = {
  id: RecordIdString;
  name: string;
};

export type ColorRecord = {
  id: RecordIdString;
  name: string;
};

export type FlavorRecord = {
  id: RecordIdString;
  name: string;
};

export type FairyRecord = {
  id: RecordIdString;
  name: string;
  affection: number;
  witch: RecordIdString;
  natures: RecordIdString[];
  favorite_color: RecordIdString;
  favorite_flavor: RecordIdString;
  favorite_entertainment: RecordIdString;
  favorite_genre: RecordIdString;
  favorite_food: RecordIdString;
  favorite_drink: RecordIdString;
  saturation: number;
  level: FairyLevel;
  expand: {
    witch: WitchesRecord;
    natures: NatureRecord[];
    favorite_color: ColorRecord;
    favorite_flavor: FlavorRecord;
    favorite_genre: GenreRecord;
    favorite_entertainment: EntertainmentItemRecord;
    favorite_food: FoodItemRecord;
    favorite_drink: FoodItemRecord;
  };
  created: string;
};

export type KidLevel = "BABY" | "TODDLER" | "KID" | "TEEN";

export type KidRecord = {
  id: RecordIdString;
  name: string;
  witch: RecordIdString;
  personality: string;
  saturation: number;
  level: KidLevel;
  expand: {
    witch: WitchesRecord;
  };
};

export type LocationType = "MEET" | "HOME";

export const LOCATION_ID_HOME = "arukf8m5k9n28qn";

export type ChildRecord = {
  id: RecordIdString;
};

export type LocationRecord = {
  id: RecordIdString;
  name: string;
  image: string;
  type: LocationType;
  description: string;
  created: string;
  updated: string;
};

export type LocationFairyRecord = {
  id: RecordIdString;
  location: RecordIdString;
  fairy: RecordIdString;
  has_meet_someone: boolean;
  created: string;
  updated: string;
  expand: {
    fairy: FairyRecord;
    location: LocationRecord;
  };
};

export type TeacherRecord = {
  id: RecordIdString;
  image: string;
};

export type MessageRecord = {
  id: RecordIdString;
  content: string;
  involved_faries: RecordIdString[];
  involved_witches: RecordIdString[];
  involved_teachers: RecordIdString[];
  expand: {
    involved_faries: FairyRecord[];
    involved_witches: WitchesRecord[];
    involved_teachers: TeacherRecord[];
  };
};

export type MessageFairyReceiverRecord = {
  id: RecordIdString;
  message: RecordIdString;
  fairy: RecordIdString;
  is_read: boolean;
  expand: {
    fairy: FairyRecord;
    message: MessageRecord;
  };
};

export type MessageWitchReceiverRecord = {
  id: RecordIdString;
  message: RecordIdString;
  witch: RecordIdString;
  is_read: boolean;
  expand: {
    witch: WitchesRecord;
    message: MessageRecord;
  };
};

export type FairyFriendsRecord = {
  id: RecordIdString;
  fairies: RecordIdString[];
  friendship: "FRIEND" | "FOE";
};
